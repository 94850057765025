<template>
    <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('fertilizerProcurement.fobCfrProcurement') }} </h4>
        </template>
        <template v-slot:body>
            <form-search :data="searchAttributes" @returned-data="searchData"/>
        </template>
    </iq-card>
    <b-row>
        <b-col md="12">
            <iq-card>
                <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('fertilizerProcurement.fobCfrProcurement') }} </h4>
                </template>
                <template v-slot:headerAction>
                    <b-button class="btn btn_add_new" @click="addNew">
                        <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                    </b-button>
                </template>
                <template v-slot:body>
                    <b-overlay :show="loadingState">
                        <b-row>
                            <b-col v-if="listData.length" md="12" class="table-responsive">
                                <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                    <template v-slot:cell(index)="data">
                                        {{ data.index + pagination.slOffset }}
                                    </template>
                                    <template v-slot:cell(type)="data">
                                        {{ data.value === 1 ? $t('fertilizerProcurement.fobProcurement') : (data.value === 2 ? $t('fertilizerProcurement.cfrProcurement') : 'N/A') }}
                                    </template>
                                    <template v-for="(slot, index) in [`created_at`, `schedule_date`, `actual_shipment_date`, `departure_date`, `eta_date`]" v-slot:[getCell1(slot)]="data">
                                        {{ data.value | dateFormat }}
                                    </template>
                                    <template v-for="(slot, index) in ['contract_year', 'lot_no', 'per_lot_amount', 'import_year', 'fertilizer_amount', 'bl_quantity']" v-slot:[getCell(slot)]="data">
                                        <div :key="index"> {{ $n(data.value, { useGrouping: false }) }} </div>
                                    </template>
                                    <template v-slot:cell(status)="data">
                                        {{ data.value === 1 ? $t('globalTrans.active') : (data.value === 2 ? $t('globalTrans.inactive') : '') }}
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <a href="javascript:" class="btn_table_action table_action_edit" title="Edit Item" @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                                        <a href="javascript:" class="btn_table_action table_action_status" title="Toggle Status" @click="toggle(data.item)"> <i class="fas" :class="data.item.status === 1 ? 'fa-toggle-on' : 'fa-toggle-off'"></i></a>
                                    </template>
                                </b-table>
                                <b-pagination
                                    v-model="pagination.currentPage"
                                    :per-page="pagination.perPage"
                                    :total-rows="pagination.totalRows"
                                    @input="dataChange"
                                />
                            </b-col>
                            <b-col v-else class="d-flex justify-content-center">
                                <span> {{ $t('globalTrans.noDataFound') }} </span>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
    </b-row>

    <b-modal id="fob-procurement-form" size="lg" :title="formTitle" :hide-footer=true :ok-title="$t('globalTrans.close')">
        <v-form :id="itemID" :key="itemID" @submitted="dataChange"/>
    </b-modal>

</b-container>
</template>

<script>
import FormSearch from '@/components/structure/form/search/FormSearch'
import VForm from './Form'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { fobCfrProcurementList, fobCfrProcurementToggle } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import { dateFormat } from '@/Utils/fliter'

export default {
    name: 'fobCfrProcurementList',
    mixins: [ModalBaseMasterList],
    components: {
        VForm, FormSearch
    },
    data () {
        return {
            search: {},
            itemID: 0,
            rows: [],
            defaultNull: '',
            currentItem: null
        }
    },
    computed: {
        getProcurementTypes () {
            return [{ value: 1, text: 'FOB' }, { value: 2, text: 'CFR' }]
        },
        sourceCountryList () {
            return this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList.filter(item => item.status === 1)
        },
        fertilizerNameList () {
            return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
        },
        formTitle () {
            return (this.itemID === 0) ? this.$t('fertilizerProcurement.fobCfrProcurement') + ' ' + this.$t('globalTrans.entry') : this.$t('fertilizerProcurement.fobCfrProcurement') + ' ' + this.$t('globalTrans.modify')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('globalTrans.type'), class: 'text-left' },
                { label: this.$t('fertilizerProcurement.procurementDate'), class: 'text-left' },
                { label: this.$t('fertilizerProcurement.lcNumber'), class: 'text-left' },
                { label: this.$t('fertilizerConfig.fertilizer_name'), class: 'text-left' },
                { label: this.$t('globalTrans.sourceCountry'), class: 'text-left' },
                { label: this.$t('fertilizerProcurement.contractYear'), class: 'text-left' },
                { label: this.$t('fertilizerProcurement.totalLotNumber'), class: 'text-left' },
                { label: this.$t('fertilizerProcurement.shipmentScheduleDate'), class: 'text-left' },
                { label: this.$t('fertilizerProcurement.amountPerLot'), class: 'text-left' },
                { label: this.$t('fertilizerProcurement.importYear'), class: 'text-left' },
                { label: this.$t('fertilizerProcurement.fertilizerPrice'), class: 'text-left' },
                { label: this.$t('fertilizerProcurement.shipName'), class: 'text-left' },
                { label: this.$t('fertilizerProcurement.bLQuantity'), class: 'text-left' },
                { label: this.$t('fertilizerProcurement.actualShipmentPeriod'), class: 'text-left' },
                { label: this.$t('fertilizerProcurement.departureDate'), class: 'text-left' },
                { label: this.$t('fertilizerProcurement.etaDate'), class: 'text-left' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]
            var keys = [
                { key: 'index' },
                { key: 'type' },
                { key: 'created_at' },
                { key: 'lc_no' },
                { key: 'fertilizer_name' },
                { key: 'country_name' },
                { key: 'contract_year' },
                { key: 'lot_no' },
                { key: 'schedule_date' },
                { key: 'per_lot_amount' },
                { key: 'import_year' },
                { key: 'fertilizer_amount' },
                { key: 'ship_name' },
                { key: 'bl_quantity' },
                { key: 'actual_shipment_date' },
                { key: 'departure_date' },
                { key: 'eta_date' },
                { key: 'status' },
                { key: 'action' }
            ]

            if (this.$i18n.locale === 'bn') {
                keys[3] = { key: 'fertilizer_name_bn' }
                keys[4] = { key: 'country_name_bn' }
                keys[11] = { key: 'ship_name_bn' }
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        searchAttributes () {
            return {
                buttonText: this.$t('globalTrans.search'),
                data: [
                    {
                        name: 'from_schedule_date',
                        type: 'Datepicker',
                        label: 'fertilizerProcurement.scheduleFrom'
                    },
                    {
                        name: 'to_schedule_date',
                        type: 'Datepicker',
                        label: 'fertilizerProcurement.scheduleTo'
                    },
                    {
                        name: 'lc_number',
                        type: 'Input',
                        label: 'fertilizerProcurement.lcNumber'
                    },
                    {
                        name: 'country_id',
                        type: 'Select',
                        label: 'globalTrans.sourceCountry',
                        options: this.sourceCountryList,
                        selectTitle: this.$t('globalTrans.select')
                    },
                    {
                        name: 'fertilizer_id',
                        type: 'Select',
                        label: 'fertilizerConfig.fertilizer_name',
                        options: this.fertilizerNameList,
                        selectTitle: this.$t('globalTrans.select')
                    },
                    {
                        name: 'ship_name',
                        type: 'Input',
                        label: 'fertilizerProcurement.shipName'
                    },
                    {
                        name: 'type',
                        type: 'Select',
                        label: 'globalTrans.type',
                        options: this.getProcurementTypes,
                        selectTitle: this.$t('globalTrans.select')
                    }
                ]
            }
        }
    },
    async created () {
        this.loadData()
    },
    methods: {
        toggle (item) {
            this.changeStatus(seedFertilizerServiceBaseUrl, fobCfrProcurementToggle, item)
        },
        formatDate (date) {
            return dateFormat(date)
        },
        edit (item) {
            this.itemID = item.id
            this.$bvModal.show('fob-procurement-form')
        },
        getCell (key) {
            return `cell(${key})`
        },
        getCell1 (key) {
            return `cell(${key})`
        },
        addNew () {
            this.itemID = 0
            this.$bvModal.show('fob-procurement-form')
        },
        dataChange () {
            this.loadData()
        },
        default () {
            return {
                id: this.rows.length,
                editable: false
            }
        },
        async searchData (payload) {
            this.search = payload
            await this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            RestApi.getData(seedFertilizerServiceBaseUrl, fobCfrProcurementList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        }
    }
}
</script>

<style scoped>

</style>
