<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="load">
                            <b-col lg="12" sm="12">
                                <form-input :data="formInputData" @change="setData" @submit="storeData">
                                    <template v-slot:submitButton>
                                        <b-button type="submit" variant="primary float-right">{{ saveBtnName }}</b-button>
                                    </template>
                                </form-input>
                            </b-col>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { fobCfrProcurementStore, fobCfrProcurementUpdate } from '../../api/routes'
import FormInput from '@/components/structure/form/validationForm/FormInput'
import { Common, Repository, helpers } from '@/mixins/helper-functions'

export default {
    name: 'FOBProcurementForm',
    props: ['id'],
    mixins: [Common],
    components: {
        FormInput
    },
    created () {
        if (this.id) {
            this.fobProcurement = this.$store.state.list.find(el => el.id === this.id)
        }
    },
    data () {
        return {
            amountLoading: false,
            load: false,
            status: null,
            formData: {},
            fobProcurement: {
                type: 1
            },
            isMarried: false,
            smrt_id: '',
            reloadComponent: 0,
            address: {
                upazillas: [],
                districts: [],
                unions: []
            }
        }
    },
    watch: {
        'fobProcurement.type': function (newVal, oldVal) {
            if (newVal === 2) {

            }
        }
    },
    computed: {
        checkType () {
            return this.fobProcurement.type === 2
        },
        getProcurementTypes () {
            return [{ value: 1, text: 'FOB' }, { value: 2, text: 'CFR' }]
        },
        fertilizerNames () {
            return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList
        },
        fertilizerCountryNames () {
            return this.$store.state.SeedsFertilizer.commonObj.fertilizerImportCountryList
        },
        saveBtnName () {
            return (this.id) ? this.$t('globalTrans.update') : this.$t('globalTrans.save')
        },
        formInputData () {
            return {
                chunk: true,
                buttonCol: 12,
                dataParent: this.fobProcurement,
                data: [
                    {
                        items: [
                            {
                                name: 'type',
                                type: 'Select',
                                options: this.getProcurementTypes,
                                label: 'globalTrans.type',
                                lg: 12,
                                labelCol: 4
                            },
                            {
                                name: 'lc_no',
                                type: 'Input',
                                inputType: 'number',
                                label: 'fertilizerProcurement.lcNumber',
                                lg: 12,
                                labelCol: 4
                            },
                            {
                                name: 'fertilizer_id',
                                type: 'Select',
                                label: 'fertilizerConfig.fertilizer_name',
                                options: this.fertilizerNames,
                                selectTitle: this.$t('globalTrans.select'),
                                lg: 12,
                                labelCol: 4
                            },
                            {
                                name: 'country_id',
                                type: 'Select',
                                label: 'globalTrans.sourceCountry',
                                options: this.fertilizerCountryNames,
                                selectTitle: this.$t('globalTrans.select'),
                                lg: 12,
                                labelCol: 4
                            },
                            {
                                name: 'contract_year',
                                label: 'fertilizerProcurement.contractYear',
                                placeholder: this.$t('fertilizerProcurement.contractYear'),
                                type: 'Select',
                                selectTitle: this.$t('globalTrans.select'),
                                options: helpers.getYearList(),
                                lg: 12,
                                labelCol: 4
                            },
                            {
                                name: 'lot_no',
                                type: 'Input',
                                inputType: 'number',
                                label: 'fertilizerProcurement.totalLotNumber',
                                lg: 12,
                                labelCol: 4
                            },
                            {
                                name: 'price',
                                type: 'Input',
                                label: 'fertilizerConfig.fertilizerPrice.fertilizerPrice',
                                lg: 12,
                                labelCol: 4
                            },
                            {
                                name: 'schedule_date',
                                type: 'Datepicker',
                                label: 'fertilizerProcurement.shipmentScheduleDate',
                                lg: 12,
                                labelCol: 4
                            },
                            {
                                name: 'per_lot_amount',
                                type: 'Input',
                                label: 'fertilizerProcurement.amountPerLot',
                                lg: 12,
                                labelCol: 4
                            },
                            {
                                name: 'import_year',
                                label: 'fertilizerProcurement.importYear',
                                type: 'Select',
                                selectTitle: this.$t('globalTrans.select'),
                                options: helpers.getYearList(),
                                lg: 12,
                                labelCol: 4,
                                rules: 'nullable'
                            },
                            {
                                name: 'fiscal_year_id',
                                label: 'sidebar.warehouseSubNav.fiscal_year',
                                type: 'Select',
                                selectTitle: this.$t('globalTrans.select'),
                                options: helpers.getYearList(),
                                lg: 12,
                                labelCol: 4
                            },
                            {
                                name: 'fertilizer_amount',
                                type: 'Input',
                                label: 'fertilizerProcurement.fertilizer_amount',
                                lg: 12,
                                labelCol: 4
                            },
                            {
                                name: 'ship_rent_cost',
                                type: 'Input',
                                label: 'fertilizerProcurement.shipRentCost',
                                lg: 12,
                                labelCol: 4,
                                onIf: this.checkType
                            },
                            {
                                name: 'total_amount',
                                type: 'Input',
                                label: 'globalTrans.totalAmount',
                                lg: 12,
                                labelCol: 4,
                                onIf: this.checkType
                            },
                            {
                                name: 'ship_name',
                                type: 'Input',
                                label: 'fertilizerProcurement.shipName',
                                lg: 12,
                                labelCol: 4
                            },
                            {
                                name: 'ship_name_bn',
                                type: 'Input',
                                label: 'fertilizerProcurement.shipNameBn',
                                lg: 12,
                                labelCol: 4
                            },
                            {
                                name: 'bl_quantity',
                                type: 'Input',
                                inputType: 'number',
                                label: 'fertilizerProcurement.bLQuantity',
                                lg: 12,
                                labelCol: 4
                            },
                            {
                                name: 'actual_shipment_date',
                                type: 'Datepicker',
                                label: 'fertilizerProcurement.actualShipmentPeriod',
                                lg: 12,
                                labelCol: 4
                            },
                            {
                                name: 'departure_date',
                                type: 'Datepicker',
                                label: 'fertilizerProcurement.departureDate',
                                lg: 12,
                                labelCol: 4
                            },
                            {
                                name: 'eta_date',
                                type: 'Datepicker',
                                label: 'fertilizerProcurement.etaDate',
                                lg: 12,
                                labelCol: 4
                            }
                        ]
                    }
                ]
            }
        }
    },
    methods: {
        setData (data) {
            this.fobProcurement = Object.assign(this.fobProcurement, data)
        },
        async storeData () {
            await Repository.create({
                data: this.fobProcurement,
                baseURL: seedFertilizerServiceBaseUrl,
                storeAPI: fobCfrProcurementStore,
                updateAPI: fobCfrProcurementUpdate,
                itemId: this.id,
                modalId: 'fob-procurement-form'
            })
        }
    }
}
</script>
